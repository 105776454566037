import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Footer from './components/Footer'; // ปรับเปลี่ยนเส้นทางไปยังไฟล์ App Bar ของคุณ
import MenuAppBar from './components/MenuAppBar'; // ปรับเปลี่ยนเส้นทางไปยังไฟล์ App Bar ของคุณ

import Home from './pages/Home';
import Team from './pages/Team';
import ERROR from './pages/404';
import Performance from './pages/Performance';


function App() {

  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    // document.getElementById('my_modal_1').showModal();
    const handleConnectionChange = () => {
      setIsOnline(window.navigator.onLine);
      if (!window.navigator.onLine) {
        alert('ไม่ได้เชื่อมต่ออินเตอร์เน็ต');
      } else {
        alert('กลับมาออนไลน์อีกครั้ง');
      }
    };

    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, []);

  
  return (
    <>
      <Router>
        <MenuAppBar />
        {/* <dialog id="my_modal_1" className="modal">
          <div className="modal-box">
            <h3 className="font-bold text-lg">ประการจาก HNAWNY CLOUD!</h3>
            <p className="py-4">HNAWNY CLOUD, ขอประกาศแจ้งปลดทีมงานในสังกัดออก และไม่มีส่วนใดเกี่ยวข้องกับบุคคลที่ใช้ชื่อภายใต้ SX2DEV (ชื่อบัญชีรับเงิน : พิชญาพร บุญมาศ) ตั้งแต่วันนี้เป็นต้นไป ขอบคุณค่ะ🙏🏻</p>
            <img src='https://cdn.hnawny.in.th/storage/e3897dc6-c818-44de-95e8-5cf894881767-IMG_5042.jpeg' alt='' />
            <div className="modal-action">
              <form method="dialog">
                <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
              </form>
            </div>
          </div>
        </dialog> */}
        {!isOnline ? (
          <></>
        ) : (
          <>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/develop-team" element={<Team />} />
              <Route path="/performance" element={<Performance />} />
              <Route path="*" element={<ERROR />} />
            </Routes>
            <Footer />
          </>
        )}
      </Router>
    </>
  );
  
}

export default App;
