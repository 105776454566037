import React from 'react'
import { useNavigate } from "react-router-dom";

function Nev() {
  const navigate = useNavigate();
  return (
    <>
    <div className="navbar bg-base-100 xs:hidden z-[10000] fixed top shadow-xl rounded-box mr-3" style={{ top: 10}}>

  <div className="navbar-start">
    <div className="dropdown">
      <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
      </div>
      <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
        <li onClick={() => navigate("/")}><h4>หน้าแรก</h4></li>
        <li onClick={() => navigate("/performance")}><h4>ผลงาน</h4></li>
        <li onClick={() => navigate("/develop-team")}><h4>ทีมงาน</h4></li>
      </ul>
    </div>
    <a className="btn btn-ghost text-xl" href='/'>Hnawny Cloud House</a>
  </div>
  <div className="navbar-center hidden lg:flex">
    <ul className="menu menu-horizontal px-1">
        <li onClick={() => navigate("/")}><h4>หน้าแรก</h4></li>
        <li onClick={() => navigate("/performance")}><h4>ผลงาน</h4></li>
        <li onClick={() => navigate("/develop-team")}><h4>ทีมงาน</h4></li>
    </ul>
  </div>
  <div className="navbar-end">
    {/* <a className="btn" onClick={() => navigate("/partners-team")}>Partners</a> */}
  </div>
    </div>
    
    </>
  )
}

export default Nev