import React, { useState, useEffect } from "react";
import Nev from '../components/MenuAppBar'; // Import component Nev

const Team = () => {
  const [teamData, setTeamData] = useState([]);

  useEffect(() => {
    // Fetch team data from team.json
    fetch("/team.json")
      .then((response) => response.json())
      .then((data) => {
        setTeamData(data);
      });
  }, []);

  return (
    <>
      <Nev /> 
      <div className="min-h-screen py-12 bg-gradient-to-t from-secondary-content via-purple-600 to-secondary-content" style={{marginTop: "8vh"}}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-200">Developer Team ทีมของพวกเรา</h2>
          <div className="mt-6 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {teamData.map((team, index) => (
              <div key={index} className="bg-white overflow-hidden shadow-md rounded-lg">
                <img className="w-full h-64 object-cover object-center" src={team.img} alt={team.name} />
                <div className="p-6">
                  <h3 className="text-lg font-semibold text-primary">{team.name}</h3>
                  <p className="mt-2 text-sm text-gray-500">{team.duty}</p>
                  <p className="mt-2 text-sm text-gray-700">{team.details}</p>
                  <a href={`https://${team.link}`} className="mt-4 bg-secondary py-3 px-3 rounded-md block text-sm font-semibold text-primary hover:text-accent">WebSite : {team.link}</a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
