import React from "react";

const Home = () => {
  return (
    <>
      <div className="hero min-h-screen bg-gradient-to-t from-secondary-content via-purple-600 to-secondary-content">

        <div className="hero-overlay bg-opacity-10"></div>
        <div className="hero-content text-center text-neutral-content hnawny_Home">
          <div className="max-w-md">
            <h1 className="mb-5 text-5xl font-bold text-white">ยินดีตอนรับ</h1>
            <h1 className="mb-5 text-5xl font-bold text-white ">
              Hnawny Cloud House
            </h1>
            <p className="mb-5 text-white">
              ให้บริการทุกท่านด้วยความจริงใจ และแก้ไขปัญหาอย่างตรงจุด
              🌻
            </p>
            <button className="btn btn-primary">ดูผลงานของเรา</button>
          </div>
        </div>
      </div>
      <div className="bg-secondary-content">
      <div className="container mx-auto px-4 py-12">
      <h2 className="text-3xl font-bold text-gray-200">ผลิตภัณฑ์ ของเรา </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 mt-3">

          <div className="bg-white overflow-hidden shadow-md rounded-lg">
            <img className="w-full h-64 object-cover object-center" src="/lima.jpeg" alt="" />
            <div className="p-6">
              <h3 className="text-xl text-primary font-bold mb-2">แพลตฟอร์ม <br/> ขายสินค้าไอเทมผ่านไลน์</h3>
              <p className="text-gray-700 mb-4">Lima (Line Manage Action)</p>
              <a href="https://lima.app" className="block mt-4 text-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700">Lima.app</a>
            </div>
          </div>

          <div className="bg-white overflow-hidden shadow-md rounded-lg">
            <img className="w-full h-64 object-cover object-center" src="/TermgameSI.jpeg" alt="" />
            <div className="p-6">
              <h3 className="text-xl text-primary font-bold mb-2">แพลตฟอร์ม <br/> เติมเกม บัตรเงินสด ไอเทมเกม</h3>
              <p className="text-gray-700 mb-4">เติมเกมสิ</p>
              <a href="https://termgamesi.app/" className="block mt-4 text-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700">Termgamesi.app</a>
            </div>
          </div>

          <div className="bg-white overflow-hidden shadow-md rounded-lg">
            <img className="w-full h-64 object-cover object-center" src="/hnawny mu.jpeg" alt="" />
            <div className="p-6">
              <h3 className="text-xl text-primary font-bold mb-2">สร้างผลงานเพลงหลายๆๆแนว</h3>
              <p className="text-gray-700 mb-4">Hnawny Music</p>
              <a href="https://www.youtube.com/@HnawnyMusic" className="block mt-4 text-center px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700">Youtube Hnawny Music</a>
            </div>
          </div>

        </div>
      </div>
    </div>
    </>
  );
};

export default Home;
