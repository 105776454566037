import React from "react";

const ERROR = () => {
  return (
    <>
      <div
        className="hero min-h-screen"
        style={{ backgroundColor: "#c70017" }}
      >
        <div className="hero-overlay bg-opacity-10"></div>
        <div className="hero-content text-center text-neutral-content">
          <div className="max-w-md">
            <h1 className="mb-5 text-5xl font-bold text-white">404</h1>
            <h1 className="mb-5 text-5xl font-bold text-white">
                This page could not be found.
            </h1>
            <p className="mb-5 text-white">Looks like you have landed in the wrong place</p>
            <button className="btn btn-primary">ย้อนกลับ</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ERROR;
