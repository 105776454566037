import React, { useState, useEffect } from "react";
import Nev from "../components/MenuAppBar";

const Team = () => {
  const [teamData, setTeamData] = useState([]);

  useEffect(() => {
    fetch("/performance.json")
      .then((response) => response.json())
      .then((data) => {
        setTeamData(data);
      });
  }, []);

  return (
    <>
      <Nev />
      <div className="min-h-screen py-12 bg-gradient-to-t from-secondary-content via-purple-600 to-secondary-content" style={{marginTop: "8vh"}}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-200">Performance ผลงานของเรา</h2>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mt-3 lg:grid-cols-3 xl:grid-cols-4">
              {teamData.map((post, index) => (
                <article key={index} className="bg-white rounded-lg overflow-hidden shadow-lg">
                  <img
                    src={post.imageUrl}
                    alt={post.name}
                    className="h-76 w-full object-cover object-center"
                  />
                  <div className="p-4">
                    <h3 className="text-lg font-semibold text-primary mb-2">{post.name}</h3>
                    <p className="text-gray-500 text-sm mb-1">{post.details}</p>
                    <p className="text-gray-500 text-sm mb-4">วันเสร็จงาน {post.date}</p>
                    <a href={post.link} className="mt-4 bg-secondary py-3 px-3 rounded-md block text-sm font-semibold text-primary hover:text-accent">เข้าชม {post.name}</a>
                    <div className="mt-4 bg-secondary py-3 px-3 rounded-md block text-sm font-semibold text-primary hover:text-accent">Budget: {post.budget} บาท</div>
                    <div className="flex items-center mt-4">
                      {post.author_img === "" ? (
                        <div class="avatar-group -space-x-6 rtl:space-x-reverse">
                          {post.author_img_all.map((img, index) => (
                            <div class="avatar">
                            <div class="w-12">
                              <img src={img} alt={index} title={index} />
                            </div>
                          </div>
                          ))}
                        </div>
                      ) : (
                        <img
                          src={post.author_img}
                          alt=""
                          className="h-10 w-10 rounded-full bg-gray-50"
                        />
                      )}
                      <div className="ml-2">
                        <p className="font-semibold text-gray-700">Dev By</p>
                        <p className="text-gray-700">{post.dev}</p>
                      </div>
                    </div>
                  </div>
                </article>
              ))}
            </div>
        </div>
      </div>
    </>
  );
};

export default Team;
